<template>
  <div class="alarm-center-alarms">
    <LoadingSpinner :isLoading="isLoading" />
    <div>
      <div class="flex">
        <div class="w-full bg-action-bar px-2 pt-0 pb-3 sm:pt-2 sm:pb-2 clearfix">
          <div class="float-right flex flex-wrap">
            <button class="btn-secondary-outline mx-2 mt-3 sm:mt-0" @click.prevent="expandUnits()">
              <span v-if="!all_expanded">
                {{ $t("expand") }}
                <BaseIcon icon="plus-circle" class="ml-1" />
              </span>
              <span v-else>
                {{ $t("minimize") }}
                <BaseIcon icon="minus-circle" class="ml-1" />
              </span>
            </button>
            <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportXLS()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="customers && customers.length > 0">
        <div class="grid bg-primary-50 rounded mt-5 py-5 px-4" v-for="customer in customers" :key="customer._id">
          <div class="font-serif text-lg font-semibold mr-1 mb-3">{{ customer.name }}</div>
          <div class="grid mt-2" v-for="(unit, index) in customer.units" :key="index">
            <div
              class="clearfix pb-2"
              v-bind:class="{ 'cursor-pointer border-b border-secondary-500': unit.alarms.length > 0 }"
              @click.prevent="
                unit.isAlarmVisible = !unit.isAlarmVisible;
                checkExpandUnit();
              "
            >
              <div class="float-left text-lg font-semibold text-primary-500 hover:text-primary-600">
                {{ unit.name }}
              </div>
              <div class="float-right" v-if="unit.alarms.length > 0">
                <BaseIcon v-if="!unit.isAlarmVisible" class="text-xl text-primary-500" icon="plus-circle" />
                <BaseIcon v-if="unit.isAlarmVisible" class="text-xl text-primary-500" icon="minus-circle" />
              </div>
            </div>
            <div class="mb-4" v-show="unit.isAlarmVisible">
              <div class="grid" v-for="(alarm, c) in unit.alarms" :key="c">
                <div class="mt-3 border-b border-secondary-400 pt-1 pb-3 clearfix">
                  <div class="float-left">
                    <span class="text-sm font-medium cursor-pointer" @click="show(alarm)">
                      <span class="last-seen text-sm mr-2" v-if="alarm.lastSeenTime" v-bind:class="{ inactive: alarm.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      <span class="hover:text-primary-500 hover:underline">{{ alarm.unit_name }} - {{ alarm.phone }}</span>
                    </span>
                  </div>
                  <div class="float-right">
                    <span class="track-alarm ml-5" v-if="alarm.lastSeenTime">
                      <router-link class="btn-green" :to="'/alarm-center/securtrack/' + alarm_center_id + '/' + alarm._id">Track <BaseIcon icon="map-marker-alt" class="ml-1"/></router-link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-8 text-center border-b" v-if="alarms && alarms.length == 0">
        {{ $t("no_alarm_found") }}
      </div>
    </div>

    <AlarmDetail :alarmInfo="alarm_info" :alarmCentrals="[alarm_center]" :sellers="sellers" :customer="customer" />
  </div>
</template>

<script>
import AlarmDetail from "@/components/modal/alarm_detail";

export default {
  name: "AlarmCenterAlarms",
  title() {
    return this.$t("page_titles.alarm_overview");
  },
  components: {
    AlarmDetail,
  },
  data() {
    return {
      isLoading: false,
      alarm_center_id: this.$route.params.id,
      alarm_center: null,
      alarms: null,
      customers: null,
      units: null,
      gps: null,
      sellers: [],
      all_expanded: false,
      alarm_info: null,
      customer: null,
    };
  },
  methods: {
    async getAlarmCenterAlarms() {
      try {
        this.isLoading = true;

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarm-center/${this.alarm_center_id}/alarms`);
        this.alarm_center = response.data.alarm_center;
        this.alarms = response.data.alarms;
        this.customers = response.data.customers;
        this.units = response.data.units;
        this.gps = response.data.gps;

        this.setPageTitle(`${this.alarm_center.name} - Larmöversikt`, "alarm_center_alarms");
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    getSellersResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    exportXLS() {
      let export_columns = [];

      this.customers.forEach((customer) => {
        customer.units.forEach((unit) => {
          unit.alarms.forEach((alarm) => {
            export_columns.push({
              Kund: customer.name,
              Verksamhet: unit.name,
              Enhetstyp: alarm.type,
              Enhetsnamn: alarm.unit_name,
              Telefonnummer: alarm.phone,
              Serienummer: alarm.modelnumber,
              Larmcentral: alarm.alarm_central,
            });
          });
        });
      });

      export_columns = _.sortBy(export_columns, ["Verksamhet", "Enhetsnamn"]);

      this.excelExport(export_columns, "larms", "larm_rapport");
    },

    getAlarmLastSeenTime: function(updatedAt) {
      return {
        lastSeen: this.moment(updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(updatedAt)),
      };
    },

    expandUnits() {
      this.all_expanded = !this.all_expanded;
      this.customers.forEach((customer) => {
        customer.units.forEach((unit) => {
          if (this.all_expanded) unit.isAlarmVisible = true;
          else unit.isAlarmVisible = false;
        });
      });
    },

    checkExpandUnit() {
      let all_expanded = false;
      this.customers.forEach((customer) => {
        customer.units.forEach((unit) => {
          if (unit.isAlarmVisible) all_expanded = true;
        });
      });

      if (all_expanded) this.all_expanded = true;
      else this.all_expanded = false;
    },

    show(alarm) {
      let u = _.find(this.units, { unit_id: alarm.parent_id });
      if (u) {
        let c = _.find(this.customers, { _id: u.customer_id });
        if (c) this.customer = c;
      }

      this.alarm_info = _.cloneDeep(alarm);

      this.$modal.show("update-alarm-contract");
    },

    hide() {
      this.alarm_info = null;
      this.customer = null;
      this.show_modal = false;
      this.visibleOverlay = false;
    },
  },

  created() {
    this.getAlarmCenterAlarms();
    this.getSellersResellers();
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.alarm_overview");
    },
  },
};
</script>
